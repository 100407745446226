.cursor_pointer {
  cursor: pointer !important;
}

.not_draggable {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.pad15 {
  width: 15px;
  min-width: 15px;
}

.pad10 {
  width: 10px;
  min-width: 10px;
}

.pad05 {
  width: 5px;
  min-width: 5px;
}


/* MOBIEL/ TABLET/ DESKTOP */
.layout_m {
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  height: auto;
}

.layout_t {
  overflow: auto;
  height: auto;
}

.layout_d {}

.layout_tm {}

.layout_dm {}

.layout_dt {}

.layout_dtm {}

@media only screen and (max-width: 400px) {

  /* ... */
  .layout_m {
    display: block !important;
  }

  .layout_t {
    display: none !important;
  }

  .layout_d {
    display: none !important;
  }

  .layout_tm {
    display: block !important;
  }

  .layout_dm {
    display: block !important;
  }

  .layout_dt {
    display: none !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .pad15 {
    width: 11px;
    min-width: 11px;
  }

  .layout_dd {
    display: none !important;
  }
}

@media only screen and (min-width: 400px) {

  /* ... */
  .layout_m {
    display: block !important;
  }

  .layout_t {
    display: none !important;
  }

  .layout_d {
    display: none !important;
  }

  .layout_tm {
    display: block !important;
  }

  .layout_dm {
    display: block !important;
  }

  .layout_dt {
    display: none !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .layout_dd {
    display: none !important;
  }

}

@media only screen and (min-width: 600px) {

  /* ... */
  .layout_m {
    display: none !important;
  }

  .layout_t {
    display: block !important;
  }

  .layout_d {
    display: none !important;
  }

  .layout_tm {
    display: block !important;
  }

  .layout_dm {
    display: block !important;
  }

  .layout_dt {
    display: block !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .layout_dd {
    display: none !important;
  }
}

@media only screen and (min-width: 781px) {

  /* ... */
  .layout_m {
    display: none !important;
  }

  .layout_t {
    display: block !important;
  }

  .layout_d {
    display: none !important;
  }

  .layout_tm {
    display: none !important;
  }

  .layout_dm {
    display: block !important;
  }

  .layout_dt {
    display: block !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .layout_dd {
    display: none !important;
  }
}

@media only screen and (min-width: 992px) {

  /* ... */
  .layout_m {
    display: none !important;
  }

  .layout_t {
    display: none !important;
  }

  .layout_d {
    display: block !important;
  }

  .layout_tm {
    display: none !important;
  }

  .layout_dm {
    display: block !important;
  }

  .layout_dt {
    display: block !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .layout_dd {
    display: none !important;
  }
}

@media only screen and (min-width: 1200px) {

  /* ... */
  .layout_m {
    display: none !important;
  }

  .layout_t {
    display: none !important;
  }

  .layout_d {
    display: block !important;
  }

  .layout_tm {
    display: none !important;
  }

  .layout_dm {
    display: block !important;
  }

  .layout_dt {
    display: block !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .layout_dd {
    display: none !important;
  }
}

@media only screen and (min-width: 1900px) {

  /* ... */
  .layout_m {
    display: none !important;
  }

  .layout_t {
    display: none !important;
  }

  .layout_d {
    display: none !important;
  }

  .layout_tm {
    display: none !important;
  }

  .layout_dm {
    display: none !important;
  }

  .layout_dt {
    display: block !important;
  }

  .layout_dtm {
    display: block !important;
  }

  .layout_dd {
    display: block !important;
  }
}


.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  z-index: 999;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #fff;
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.line_price {

  color: #959595;
  text-decoration: line-through;
}


.swiper-pagination-bullets {

  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: center;
  bottom: 20px;
  margin-bottom: 20px;
  position: absolute;
  z-index: 77;
}

.swiper-pagination-bullet { 
  width: 28px;
  height: 4px;
  border-radius: 0px;
  opacity: 0.3;
  background-color: #FFFFFF;
  margin-left: 1.5px;
  margin-right: 1.5px;

}

.swiper-pagination-bullet-active {
  width: 28px;
  height: 4px;
  border-radius: 0px;
  opacity: 1.0;
  background-color: #FFFFFF;
}

.brandmorearrow01 {
  width: 10px;
  height: 10px;
  object-fit: contain;
  margin-top: 2px;
  margin-left: 8px;
}



.layout_m .swiper-pagination-bullets {

  display: flex;
  flex-direction: row;
  width: 100vw;
  align-items: center;
  justify-content: center;
  bottom: 10px;
  position: absolute;
  z-index: 77;
  margin-bottom: -4.5px;
}

.layout_m .swiper-pagination-bullet { 
  width: 50vw;
  height: 4px;
  border-radius: 0px;
  opacity: 0.3;
  background-color: #FFFFFF;
  margin-left: 0px !important;
  margin-right: 0px !important;

}

.layout_m .swiper-pagination-bullet-active {
  width: 50vw;
  height: 4px;
  border-radius: 0px;
  opacity: 1.0;
  background-color: #FFFFFF;
}

@keyframes loading {
  0% {
    transform: translateX(0);
  }

  50%,
  100% {
    transform: translateX(460px);
  }
}

.skeleton-item::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 100%;
  background: linear-gradient(to right, #f2f2f2, #ddd, #f2f2f2);
  animation: loading 2s infinite linear;
}

.fab_button0 {
  width: 60px;
  height: 60px;
  border-radius: 50%;

  position: fixed;
  bottom: 80px;
  right: 28px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fab_button0 img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.fab_button2 {
  width: 60px;
  height: 60px;
  border-radius: 50%;

  position: fixed;
  bottom: 160px;
  right: 28px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.fab_button2 img {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.dropbtn {
  color: white; 
  font-size: 16px;
  border: none;
  background: transparent;
}

.phonePreDropWrapper4 {
  width: 104px;
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  flex-direction: column;
  overflow: scroll;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  background-color: #fff;
  border-radius: 8px;

}

.phonePreDropItem4 {
  width: calc(100% - 30px);
  height: 40px; 
  padding-left: 5px;
  padding-right: 5px;
  font-size: 14px;

  font-family: "Pretendard-Regular";

  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.dropup {
  position: relative;
  display: inline-block;
}

.dropup-content {
  display: none;
  position: absolute;
  min-width: 160px;
  bottom: 50px;
  right: 20px;
  z-index: 1;
}

.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #000;
  color: #fff;

}

.dropup-content a:hover {
  background-color: #000;
}

.dropup-content a:active {
  background-color: #000;
}

.dropup:hover .dropup-content {
  display: block;
}

.dropup:active .dropup-content {
  display: block;
}

.dropup:hover .dropbtn {}

.dropup:active .dropbtn {}


.logincomp_index {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin-top: 100px;
}

.logincomp_index .logincomp_group-wrapper {
  background-color: #ffffff;
  height: 578px;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.logincomp_index .logincomp_group {
  height: 485px;
  left: 50%;
  transform: translate(-50%);
  position: relative; 
  width: 322px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.logincomp_index .logincomp_overlap {
  background-color: #ffffff;
  border: 1px solid;

  border-radius: 4px;
  height: 48px;
  left: 5px;
  position: absolute;
  top: 301px;
  width: 320px;

  left: 50%;
  transform: translate(-50%);
}

.logincomp_index .logincomp_object-nnn {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 10px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  top: 16px;
  white-space: nowrap;

  max-width: 260px;
}

.logincomp_index .logincomp_overlap-group {
  height: 77px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  top: 0;
  width: 179px;
}

.logincomp_index .logincomp_mask-group {
  height: 69px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 168px;
}

.logincomp_index .logincomp_text-wrapper {
  color: #000000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 5px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 58px;
  white-space: nowrap;
}

.logincomp_index .logincomp_div-wrapper {
  background-color: #333333;
  border-radius: 4px;
  height: 48px;
  left: 5px;
  position: absolute;
  top: 393px;
  width: 320px;

  left: 50%;
  transform: translate(-50%);
  cursor: pointer;
}

.logincomp_index .logincomp_div {
  color: #ffffff;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 50%;
  transform: translate(-50%);
  letter-spacing: 0;
  line-height: 16px;
  position: absolute;
  text-align: center;
  top: 16px;
  white-space: nowrap;

  display: flex;
  align-items: center;
  justify-content: center;
}

.logincomp_index .logincomp_group-2 {
  height: 106px;
  left: 6px;
  position: absolute;
  top: 128px;
  width: 320px;
  left: 50%;
  transform: translate(-50%);
}

.logincomp_index .logincomp_group-3 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e1e1e1;
  border-radius: 4px;
  height: 48px;
  left: 50%;
  transform: translate(-50%);
  position: absolute;
  top: 0;
  width: 320px;

  cursor: pointer;
}

.logincomp_index .logincomp_group-4 {
  height: 24px;
  left: 15px;
  position: absolute;
  top: 11px;
  width: 24px;
}

.logincomp_index .logincomp_group-5 {
  height: 20px;
  left: 2px;
  position: relative;
  top: 2px;
  width: 19px;
}

.logincomp_index .logincomp_overlap-group-wrapper {
  height: 20px;

}

.logincomp_index .logincomp_overlap-group-2 {
  height: 20px;
  position: relative;
  width: 19px;
}

.logincomp_index .logincomp_group-6 {
  background-image: url(https://cdn.animaapp.com/projects/64ffaafc005c8f21dceb734b/releases/65793217ac10584c0429b343/img/group-45@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 9px;
  left: 10px;
  position: absolute;
  top: 8px;
  width: 9px;
}

.logincomp_index .logincomp_group-7 {
  background-image: url(https://cdn.animaapp.com/projects/64ffaafc005c8f21dceb734b/releases/65793217ac10584c0429b343/img/group-47@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 8px;
  left: 1px;
  position: absolute;
  top: 12px;
  width: 15px;
}

.logincomp_index .logincomp_group-8 {
  background-image: url(https://cdn.animaapp.com/projects/64ffaafc005c8f21dceb734b/releases/65793217ac10584c0429b343/img/group-49@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 9px;
  left: 0;
  position: absolute;
  top: 5px;
  width: 4px;
}



.logincomp_index .logincomp_group-9 {
  background-image: url(https://cdn.animaapp.com/projects/64ffaafc005c8f21dceb734b/releases/65793217ac10584c0429b343/img/group-51@1x.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 8px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 15px;
}

.logincomp_index .logincomp_text-wrapper-2 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 122px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.logincomp_index .logincomp_overlap-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #e1e1e1;
  border-radius: 4px;
  height: 48px;
  left: 0;
  position: absolute;
  top: 58px;
  width: 320px;
  cursor: pointer;
}

.logincomp_index .logincomp_overlap-3 {
  height: 24px;
  left: 16px;
  position: absolute;
  top: 12px;
  width: 24px;
}

.logincomp_index .logincomp_group-10 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.logincomp_index .logincomp_group-11 {
  height: 20px;
  left: 4px;
  position: relative;
  top: 2px;
  width: 16px;
}

.logincomp_index .logincomp_group-12 {
  height: 20px;
  position: relative;
}

.logincomp_index .logincomp_path {
  height: 15px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 5px;
  width: 16px;
}

.logincomp_index .logincomp_img {
  height: 5px;
  left: 8px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 4px;
}


.logincomp_index .logincomp_text-wrapper-3 {
  color: #333333;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 117px;
  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 8px;
  white-space: nowrap;
}

.logincomp_index .logincomp_group-14 {
  height: 16px;
  left: 18px;
  position: absolute;
  top: 462px;
  width: 190px; 

}

.logincomp_index .logincomp_text-wrapper-4 {
  color: #333333;
  font-family: "Pretendard-SemiBold", Helvetica;
  font-size: 13px;
  font-weight: 600;
  left: 120px;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.logincomp_index .logincomp_text-wrapper-5 {
  color: #959595;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 13px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.logincomp_index .logincomp_rectangle {
  background-color: #333333;
  height: 1px;
  left: 138px;
  position: absolute;
  top: 484px;
  width: 69px;
}

.logincomp_index .logincomp_p {
  color: #db0000;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 42px;
  letter-spacing: 0;
  line-height: 14px;
  position: absolute;
  top: 344px;
  white-space: nowrap;
}

.logincomp_index .logincomp_group-15 {
  border: 1px solid #db0000;
  border-radius: 50%;
  height: 18px;
  left: 16px;
  position: absolute;
  top: 354px;
  width: 18px;
}

.logincomp_index .logincomp_group-16 {
  height: 11px;
  left: 8px;
  position: relative;
  top: 4px;
  transform: rotate(180deg);
  width: 2px;
}

.logincomp_index .logincomp_rectangle-2 {
  background-color: #db0a16;
  height: 2px;
  left: 0;
  position: absolute;
  top: 0;
  width: 2px;
}

.logincomp_index .logincomp_rectangle-3 {
  background-color: #db0a16;
  height: 6px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 2px;
}

.logincomp_index .logincomp_rectangle-4 {
  background-color: #e1e1e1;
  height: 1px;
  left: 0px;
  position: absolute;
  top: 270px;
  width: 120px;
}

.logincomp_index .logincomp_path-2 {
  height: 1px;
  object-fit: cover;
  position: absolute;
  top: 271px;
  left: 190px;
  width: 120px;
}

.logincomp_index .logincomp_text-wrapper-6 {
  color: #959595;
  font-family: "Pretendard-Regular", Helvetica;
  font-size: 13px;
  font-weight: 400;

  letter-spacing: 0;
  line-height: 28px;
  position: absolute;
  text-align: center;
  top: 256px;
  white-space: nowrap;
  left: 155px;
  left: 50%;
  tranfrom: translate(-50%);
}


.hover_overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #e1e1e1;
}

.hover_container:hover .hover_overlay {
  opacity: 0.5;
}

.delivery_diff_text0 {

  font-family: "Pretendard-Regular";
  font-size: 13px;
  margin-top: 7px;
}

.charcoal_text0 {
  color: #959595;
  font-weight: 700;
}

.blue_text0 {
  color: #2E76FF;
}

.remain_text0 {

  font-family: "Pretendard-Regular";
  font-size: 13px;
  margin-left: 40px;
}

.remain_text1 {

  font-family: "Pretendard-Regular";
  font-size: 11px;
  margin-left: 16px;
}

.infinity-1 {
  width: 90px;
  height: 14px;
  background:
    radial-gradient(circle 7px at bottom, #fff 92%, #0000) 0 0,
    radial-gradient(circle 7px at top, #fff 92%, #0000) 0 100%;
  background-size: calc(100%/4) 50%;
  background-repeat: repeat-x;
  animation: i1 1s infinite;
}

@keyframes i1 {

  80%,
  100% {
    background-position: calc(100%/3) 0, calc(100%/-3) 100%
  }
}

.infinity-2 {
  width: 90px;
  height: 14px;
  background:
    conic-gradient(from 135deg at top, #fff 90deg, #0000 0) 0 0,
    conic-gradient(from -45deg at bottom, #fff 90deg, #0000 0) 0 100%;
  background-size: calc(100%/4) 50%;
  background-repeat: repeat-x;
  animation: i2 1s infinite;
}

@keyframes i2 {

  80%,
  100% {
    background-position: calc(100%/3) 0, calc(100%/-3) 100%
  }
}

.infinity-3 {
  width: 90px;
  height: 14px;
  background:
    radial-gradient(circle 7px at bottom, #fff 92%, #0000) calc(100%/2) 0,
    radial-gradient(circle 7px at top, #fff 92%, #0000) calc(100%/2) 100%,
    conic-gradient(from 135deg at top, #fff 90deg, #0000 0) 0 0,
    conic-gradient(from -45deg at bottom, #fff 90deg, #0000 0) 0 100%;
  background-size: calc(100%/2) 50%;
  background-repeat: repeat-x;
  animation: i3 3s infinite;
}

@keyframes i3 {
  0% {
    background-position: calc(100%/2) 0, calc(100%/2) 100%, 0 0, 0 100%
  }

  20%,
  30% {
    background-position: calc(3*100%/4) 0, calc(100%/4) 100%, calc(100%/4) 0, calc(100%/-4) 100%
  }

  45%,
  55% {
    background-position: 100% 0, 0 100%, calc(100%/2) 0, calc(100%/-2) 100%
  }

  70%,
  80% {
    background-position: calc(5*100%/4) 0, calc(100%/-4) 100%, calc(3*100%/4) 0, calc(3*100%/-4) 100%
  }

  100% {
    background-position: calc(3*100%/2) 0, calc(100%/-2) 100%, 100% 0, -100% 100%
  }
}

.infinity-4 {
  width: 90px;
  height: 14px;
  background:
    radial-gradient(circle closest-side, #fff 92%, #0000) calc(100%/-4) 0,
    radial-gradient(circle closest-side, #fff 92%, #0000) calc(100%/4) 0;
  background-size: calc(100%/2) 100%;
  animation: i4 1.5s infinite;
}

@keyframes i4 {
  0% {
    background-position: calc(100%/-4) 0, calc(100%/4) 0
  }

  50% {
    background-position: calc(100%/-4) -14px, calc(100%/4) 14px
  }

  100% {
    background-position: calc(100%/4) -14px, calc(3*100%/4) 14px
  }
}

.infinity-5 {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #fff 90deg, #0000 0);
  background:
    var(--g),
    var(--g) 10px 10px,
    var(--g) 20px 20px;
  background-size: 50% 50%;
  animation: i5 1s infinite;
}

@keyframes i5 {

  90%,
  100% {
    background-position: -30px 30px, -20px 40px, -10px 50px
  }
}

.infinity-6 {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #fff 90deg, #0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: i6 1s infinite;
  z-index: 999;
}

@keyframes i6 {
  0% {
    background-position: 0 0, 10px 10px, 20px 20px
  }

  50% {
    background-position: 0 20px, 10px 10px, 20px 0
  }

  100% {
    background-position: 20px 20px, 10px 10px, 0 0
  }
}

.infinity-7 {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #fff 90deg, #0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: i7 1s infinite;
}

@keyframes i7 {
  0% {
    background-position: 0 0, 10px 10px, 20px 20px
  }

  33% {
    background-position: -30px 0, 10px 10px, 20px 20px
  }

  66% {
    background-position: -30px 0, -20px 10px, 20px 20px
  }

  100% {
    background-position: -30px 0, -20px 10px, -10px 20px
  }
}

.infinity-8 {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #fff 90deg, #0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: i8 1s infinite;
}

@keyframes i8 {
  0% {
    background-position: 0 0, 10px 10px, 20px 20px
  }

  33% {
    background-position: -30px 0, 10px 10px, 20px 20px
  }

  66% {
    background-position: -30px 0, 10px 40px, 20px 20px
  }

  100% {
    background-position: -30px 0, 10px 40px, 50px 20px
  }
}

.infinity-9 {
  width: 60px;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #fff 90deg, #0000 0);
  background: var(--g), var(--g), var(--g);
  background-size: 50% 50%;
  animation: i9 1s infinite;
}

@keyframes i9 {
  0% {
    background-position: 0 0, 10px 10px, 20px 20px
  }

  33% {
    background-position: 10px 10px
  }

  66% {
    background-position: 0 20px, 10px 10px, 20px 0
  }

  100% {
    background-position: 0 0, 10px 10px, 20px 20px
  }
}

.infinity-10 {
  width: 60px;
  display: flex;
  align-items: flex-start;
  aspect-ratio: 1;
}

.infinity-10:before,
.infinity-10:after {
  content: "";
  flex: 1;
  aspect-ratio: 1;
  --g: conic-gradient(from -90deg at 10px 10px, #fff 90deg, #0000 0);
  background: var(--g), var(--g), var(--g);
  filter: drop-shadow(30px 30px 0 #fff);
  animation: i10 1s infinite;
}

.infinity-10:after {
  transform: scaleX(-1);
}

@keyframes i10 {
  0% {
    background-position: 0 0, 10px 10px, 20px 20px
  }

  33% {
    background-position: 10px 10px
  }

  66% {
    background-position: 0 20px, 10px 10px, 20px 0
  }

  100% {
    background-position: 0 0, 10px 10px, 20px 20px
  }
}